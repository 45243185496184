import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "../styles/Clients.css";

gsap.registerPlugin(ScrollTrigger);

export const Clients = ({API_URL}) => {

  const [clientsList, setClientsList] = useState(null);
  const [clientsDirectory, setClientsDirectory] = useState(null);
  const [fetchError, setFetchError] = useState(null);

  const main = useRef();

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch(API_URL);
        if (!response.ok) throw Error("Did not receive expected image");
        const items = await response.json();      

        setClientsDirectory(items.clients.srcURL);
        setClientsList(items.clients.list);
       
        setFetchError(null);
      } catch (err) {
        setFetchError(err.message);
      }
    };

    getData();
  }, []);

  useLayoutEffect(() => {
    const ctx = gsap.context((self) => {
      const texts = self.selector(".scrollEffect");
      texts.forEach((item) => {
        gsap.from(item, {
          y: 50,
          autoAlpha: 0,
          scrollTrigger: {
            trigger: item,
            start: "bottom bottom",
            end: "top 60%",
            scrub: true,
          },
        });
      });
    }, main); // <- Scope!
    return () => ctx.revert(); // <- Cleanup!
  });

  return (
    <section className="clients container" ref={main}>
        <h2 className="scrollEffect">We work with</h2>
        <div className="clientsLogos">
          {clientsList &&
            clientsList.map((item, index) => (
              <div key={index}>
                <img src={clientsDirectory + item} className="img-fluid" />
              </div>
            ))}
        </div>
        <span className="dash"></span>
    </section>
  );
};
