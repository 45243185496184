import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from "react-router-dom";
import "../styles/AboutMain.css";

gsap.registerPlugin(ScrollTrigger);

export const About = ({API_URL}) => {
  const [fetchError, setFetchError] = useState(null);
  const [aboutData, setAboutData] = useState(null);

  const main = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context((self) => {
      const texts = self.selector('.scrollEffect');
      texts.forEach((item) => {
        gsap.from(item, {
          y: 50,
          autoAlpha: 0,
          scrollTrigger: {
            trigger: item,
            start: 'bottom bottom',
            end: 'top 60%',
            scrub: true
          },
        });
      });
    }, main); // <- Scope!
    return () => ctx.revert(); // <- Cleanup!
  });

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch(API_URL);
        if (!response.ok) throw Error("Did not receive expected image");
        const items = await response.json();        

        setAboutData(items.about);
        setFetchError(null);
      } catch (err) {
        setFetchError(err.message);
      }
    };

    getData();
  }, []);

  return (
    <figure className="about" ref={main}>
      <div className="about-img">
        {aboutData && <img src={aboutData.imgURL} alt="team photo" />}
        <figcaption className="mobileDisplay about-overlay"></figcaption>
      </div>

      <figcaption className="figcaption">
        <span className="dash" />
        <div className="content">
          <div className="content_item">
            {aboutData && <h2 className="scrollEffect">{aboutData.text}</h2>}
          </div>
          <div className="content_item--btn">
            <Link
              to="about" className="secondaryBtn"
            >Meet us</Link>
          </div>
        </div>
      </figcaption>
    </figure>
  );
};
