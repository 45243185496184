import React, { useState, useEffect } from "react";
import "../styles/Portfolio.css";
import { Clients } from "../main-page/Clients";
import { Link } from "react-router-dom";
import {bgDefault} from '../App';

export const Portfolio = ({ setBackgroundColor, API_URL }) => {

  const [fetchError, setFetchError] = useState(null);
  const [projectsList, setProjectsList] = useState([]);
  const [projectsDirectory, setProjectsDirectory] = useState("");

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch(API_URL);
        if (!response.ok) throw Error("Did not receive expected image");
        const items = await response.json();

        setProjectsDirectory(items.projects.srcURL);
        setProjectsList(items.projects.mainList);

        setFetchError(null);
      } catch (err) {
        setFetchError(err.message);
      }
    };

    getData();
    setBackgroundColor(bgDefault);
  }, []);

  return (
    <>
      {projectsList && projectsDirectory && (
        <section className="portfolio">
          <div className="portfolioHeader">
            <h2>Portfolio</h2>
            <span className="dash"></span>
          </div>

          <div className="portfolio-grid">
            {projectsList &&
              projectsList.map((proj, index) => {

                return (
                  <Link to={`/portfolio/${proj.id}`} className={`item`}>
                    <div
                      key={index}
                      style={{
                        backgroundImage: `url(${projectsDirectory + proj.img})`
                      }}
                    >
                      <div className="overlay"></div>
                      <aside className="project-details">
                        <h2>{proj.name}</h2>
                        <div className="project-category">
                          {proj.category.map((el) => (
                            <span>{el}</span>
                          ))}
                        </div>
                      </aside>
                    </div>
                  </Link>
                );
              })}
          </div>
        </section>
      )}

      <Clients API_URL={API_URL} />
    </>
  );
};
