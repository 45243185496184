import React, { useState, useEffect } from "react";

import { Route, Routes } from "react-router-dom";
import { Layout } from "./layout/Layout";
import { Home } from "./main-page/Home";
import { Portfolio } from "./portfolio/Portfolio";
import { Project } from "./portfolio/Project";
import { About } from "./about/About";
import { ScrollToTop } from "./layout/ScrollToTop";

export const bgDefault = "#f6f6f5";

function App() {
  const API_URL = "http://dev.wytworniastudio.pl/data/data.json";

  const [isNavOpen, setIsNavOpen] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState(bgDefault);
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    setBgColorHandler(backgroundColor);
    if (backgroundColor === "#2B2E3F") setDarkMode(true);
    else setDarkMode(false);
  }, [backgroundColor]);

  const setBgColorHandler = (backgroundColor) => {
    const body = document.getElementsByTagName("body");
    body[0].style.backgroundColor = backgroundColor;
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout
            setIsNavOpen={setIsNavOpen}
            isNavOpen={isNavOpen}
            darkMode={darkMode}
            API_URL={API_URL}
          />
        }
      >
        <Route
          index
          element={
            <Home
              setBackgroundColor={setBackgroundColor}
              darkMode={darkMode}
              API_URL={API_URL}
            />
          }
        />
        <Route path="portfolio">
          <Route
            index
            element={
              <Portfolio
                setBackgroundColor={setBackgroundColor}
                API_URL={API_URL}
              />
            }
          />
          <Route
            path=":id"
            element={
              <Project
                setBackgroundColor={setBackgroundColor}
                API_URL={API_URL}
              />
            }
          />
        </Route>
        <Route
          path="about"
          element={<About setBackgroundColor={setBackgroundColor} />}
        />
      </Route>
    </Routes>
  );
}

export default App;
