import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../styles/PortfolioMain.css";
import ArrowIcon from "../icons/arrow-right.svg";

export const PortfolioMain = ({ API_URL }) => {
  const [projectsList, setProjectsList] = useState([]);
  const [fetchError, setFetchError] = useState(null);
  const [indexItem, setIndexItem] = useState(0);
  const [isMouseOver, setIsMouseOver] = useState(false);

  useEffect(() => {
      let slider = setInterval(() => {
        setIndexItem(indexItem + 1);
      }, 3000);
      return () => clearInterval(slider);
  }, [indexItem]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch(API_URL);
        if (!response.ok) throw Error("Did not receive expected image");
        const items = await response.json();

        setProjectsList(items.projects.sliderList);

        setFetchError(null);
      } catch (err) {
        setFetchError(err.message);
      }
    };

    getData();

  }, []);

  useEffect(() => {
    const lastIndex = projectsList.length - 1;
    if (indexItem < 0) {
      setIndexItem(lastIndex);
    }
    if (indexItem > lastIndex) {
      setIndexItem(0);
    }
    //setIndexItem((indexItem +1) % lastIndex);
  }, [indexItem, projectsList]);

  return (
    <>
      {projectsList && (
        <section className="portfolio-main-box">
          <div className="portfolio-main">
          <h3 className="mobileDisplay container">Portfolio</h3>
            {projectsList.map((project, projectIndex) => {
                let position = "nextSlide";

                if (projectIndex === indexItem) {
                  position = "activeSlide";
                }
                if (
                  projectIndex === indexItem - 1 ||
                  (indexItem === 0 && projectIndex === projectsList.length - 1)
                ) {
                  position = "lastSlide";
                }

              return (
                <div key={projectIndex} className={`item ${position}`}>
                  <article className="container">
                    <h3 className="mobileHidden">Portfolio</h3>
                    <div
                      className="desc"
                      onMouseOver={() => {
                        setIsMouseOver(true);
                        console.log("mouseOver");
                      }}
                      onMouseLeave={() => {
                        setIsMouseOver(false);
                        console.log("mouseLeave");
                      }}
                    >
                      <span className="portfolio-category">
                        {project.category[0]}
                      </span>
                      <p className="portfolio-title">{project.name}</p>
                      <p className="portfolio-desc">{project.shortDesc}</p>
                      <Link
                        to={`/portfolio/${project.id}`}
                        className="portfolio-link"
                      >
                        More info
                        <img src={ArrowIcon} width="24" height="24" />
                      </Link>
                      <Link to="/portfolio" className="secondaryBtn">
                        See more projects
                      </Link>
                    </div>
                  </article>
                  <figure>
                    <div className="slide">
                      <img src={project.img} className="imgSlide"/>
                    </div>
                  </figure>
                </div>
              );
            })}
          </div>
        </section>
      )}
    </>
  );
};
