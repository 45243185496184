import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { gsap } from "gsap";
import "../styles/About.css";

export const About = ({ setBackgroundColor }) => {
  const API_URL = "http://dev.wytworniastudio.pl/data/about.json";
  const [about, setAbout] = useState(null);
  const [fetchError, setFetchError] = useState(null);
  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const main = useRef();

  useLayoutEffect(() => {

    const ctx = gsap.context((self) => {
      const horizontalSections = gsap.utils.toArray('section.process');

      if (windowSize.current[0] > 768) {
        horizontalSections.forEach(function (sec, i) {

          var thisPinWrap = sec.querySelector('.pin-wrap');
          var thisAnimWrap = thisPinWrap.querySelector('.animation-wrap');

          var getToValue = () => -(thisAnimWrap.scrollWidth - window.innerWidth + 200);

          gsap.fromTo(thisAnimWrap, {
            x: () => thisAnimWrap.classList.contains('to-right') ? 0 : getToValue()
          }, {
            x: () => thisAnimWrap.classList.contains('to-right') ? getToValue() : 0,
            ease: "none",
            scrollTrigger: {
              trigger: sec,
              start: "top top",
              end: () => "+=" + (thisAnimWrap.scrollWidth - window.innerWidth + 200),
              pin: thisPinWrap,
              invalidateOnRefresh: true,
              //anticipatePin: 1,
              scrub: true,
              //markers: true,
            }
          });

        });
      }
    }, main); // <- Scope!
    return () => ctx.revert(); // <- Cleanup!
  });

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch(API_URL);
        if (!response.ok) throw Error("Did not receive expected image");
        const items = await response.json();

        setAbout(items.about);
        setFetchError(null);
      } catch (err) {
        setFetchError(err.message);
      }
    };

    getData();
    setBackgroundColor("#2B2E3F");
  }, []);

  return (
    <>
      {about && (
        <main className="dark-mode" ref={main}>
          <section className="subheader_about container">
            <div className="first">
              <h2>
                Who
                <br className="mobileHidden" /> makes up
                <br /> the team
              </h2>
              <span className="dash"></span>
            </div>
            <div className="second">
              <h3>{about.hero}</h3>
            </div>
          </section>
          <section className="aboutTeam">
            <div className="teamPic">
              <img src={about.imgSRC + "about1.jpg"} className="img-fluid" />
              <span className="dash"></span>
            </div>
            <div className="teamNames">
              <p>{about.team_desc}</p>
              <ul className="names">
                {about.team_members.map((item) => (
                  <li key={item.name}>
                    <h4>{item.name}</h4>
                    <span className="role">{item.role}</span>
                  </li>
                ))}
              </ul>
              <p>We work with</p>
              <ul className="names workWithNames">
                {about.team_others.map((item) => (
                  <li key={item.name}>
                    <h4>{item.name}</h4>
                    <span className="role">{item.role}</span>
                  </li>
                ))}
              </ul>
            </div>
          </section>
          <section className="process container">
            <div className="pin-wrap">
              <h3>
                Our design process
                <br />- 4 steps
              </h3>
              <div className="process_container">
                <div className="steps animation-wrap to-right">
                  {about.process.map((step) => (
                    <div className="item active" key={step.step_id}>
                      <div className="itemHeader">
                        <div className="itemHeaderNumber">
                          <h1>{step.step_id}</h1>
                        </div>
                        <h3>{step.step_name}</h3>
                      </div>
                      <p>{step.step_desc}</p>
                    </div>
                  ))}
                </div>
                <section className="boast mobileHidden">
                  <div className="boastContainer">
                    <div className="left">
                      <h3>We can boast</h3>
                      <ul>
                        {about.awards.map((item, index) => (
                          <li key={index}>
                            <p>{item}</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="right">
                      <div className="firstPic">
                        <div className="firstPicContainer">
                          <img
                            src={about.imgSRC + "about2.jpg"}
                            className="img-fluid"
                          />
                          <span className="dash" />
                        </div>
                      </div>
                      <div className="secondPic">
                        <img
                          src={about.imgSRC + "about3.jpg"}
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </section>
          <section className="boast mobileDisplay">
                  <div className="boastContainer">
                    <div className="left">
                      <h3>We can boast</h3>
                      <ul>
                        {about.awards.map((item, index) => (
                          <li key={index}>
                            <p>{item}</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="right">
                      <div className="firstPic">
                        <div className="firstPicContainer">
                          <img
                            src={about.imgSRC + "about2.jpg"}
                            className="img-fluid"
                          />
                          <span className="dash" />
                        </div>
                      </div>
                      <div className="secondPic">
                        <img
                          src={about.imgSRC + "about3.jpg"}
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </section>
        </main>
      )}
    </>
  );
};
