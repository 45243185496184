import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import "../styles/Project.css";

export const Project = ({ setBackgroundColor }) => {
  const API_PROJECTS_URL = "http://dev.wytworniastudio.pl/data/projects.json";

  const [project, setProject] = useState(null);
  const [fetchError, setFetchError] = useState(null);

  const {id} = useParams();

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch(API_PROJECTS_URL);
        if (!response.ok) throw Error("Did not receive expected image");
        const items = await response.json();

        const project = items.projects.find(proj => (proj.id).toString() === id);
        setProject(project);

        setBackgroundColor(project.color);
        setFetchError(null);
      } catch (err) {
        setFetchError(err.message);
      }
    };

    getData();
  }, []);

  return (
    <>
      {project && (
        <section className="project">
          <div className="container">
            <h1 className="h2">{project.title}</h1>
            <span className="dash"></span>
          </div>
          <section className="project-desc container">
            <div className="desc">
              <p>{project.paragraph1}</p>
              {project.paragraph2 && <p>{project.paragraph2}</p>}
            </div>
            <div className="category">
              <p>Project</p>
              <div className="project-category">
                {project.category.map((item) => (
                  <span>{item}</span>
                ))}
              </div>
            </div>
          </section>
          <img src={project.pic1} className="img-fluid img1" />
          <div className="container project-subheader">
            <h3>Wyzwania</h3>
          </div>
          <section className="project-challenges container">
            <div className="challenges">
              <ul>
                {project.challenges.map((item) => (
                  <li>{item}</li>
                ))}
              </ul>
            </div>
            <div className="team">
              <p>Zespół projektowy</p>
              <ul>
                {project.team.map((item) => (
                  <li>{item}</li>
                ))}
              </ul>
            </div>
          </section>
          <img src={project.pic2} className="img-fluid img2" />
          <div className="container project-subheader">
          <h3>Rezultat</h3>
          </div>
          <section className="project-result container">
            <div className="result">
              <p>{project.result}</p>
            </div>
            <div className="elements">
              <p>Spis elementów</p>
              <ul>
                {project.elements.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          </section>
          {project.picLast.map((item, index) => (
            <img src={item} className={`img-fluid img${index+3}`} />
          ))}
        </section>
      )}
    </>
  );
};
