import React, { useRef, useLayoutEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import LogoLong from "./../logo.svg";
import LogoWhite from "./../logo_white.svg";
import PaniPrestaWhite from "./../icons/panipresta_white.svg";
import PaniPresta from "./../icons/panipresta.svg";
import "../styles/Footer.css";
import { motion, useTransform, useMotionValue } from "framer-motion";

gsap.registerPlugin(ScrollTrigger);

export const Footer = ({ darkMode, CTA_1Line, CTA_2Line }) => {
  const main = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context((self) => {
      const texts = self.selector(".footerEffect");
      texts.forEach((item) => {
        gsap.from(item, {
          y: 50,
          autoAlpha: 0,
          scrollTrigger: {
            trigger: item,
            start: "bottom bottom",
            end: "top 20%",
            scrub: true,
          },
        });
      });
    }, main); // <- Scope!
    return () => ctx.revert(); // <- Cleanup!
  });
  const x = useMotionValue(0);

  return (
    <section
      className={darkMode ? "dark-mode footer container" : "footer container"}
      ref={main}
    >
      <h2 className="footerEffect">
        {CTA_1Line}
        <br />
        {CTA_2Line}
      </h2>

      <footer>
        <div className="footer-logo">
          <a href="https://wytworniastudio.pl">
            <img
              src={darkMode ? LogoWhite : LogoLong}
              alt="Wytwórnia Studio logo"
              className="logo-footer"
            />
          </a>
          <div className="copyright">
            <img
              src={darkMode ? PaniPrestaWhite : PaniPresta}
              alt="Pani Presta Development"
              className="panipresta-logo"
            />
            <p>development © 2023</p>
          </div>
        </div>

        <div className="footer-contact">
          <p>Contact</p>
          <ul>
            <li>
              <a href="mailto:office@wytworniastudio.pl">
                office@wytworniastudio.pl
              </a>
            </li>
            <li>
              <a href="tel:+48693195850">+48 693 195 850</a>
            </li>
            <li className="margin">
              <a href="#">LinkedIn</a>
            </li>
            <li className="margin">
              <a href="#">Instagram</a>
            </li>
          </ul>
        </div>
      </footer>
    </section>
  );
};
