import React, {useState, useEffect} from "react";
import { Footer } from "./Footer";
import { Outlet } from "react-router-dom";
import { Navigation } from "./Navigation";
import { ScrollToTop } from "./ScrollToTop";

export const Layout = ({ setIsNavOpen, isNavOpen, darkMode, API_URL}) => {

  const [CTA_1Line, setCTA_1Line] = useState("Do you like what we've created?");
  const [CTA_2Line, setCTA_2Line] = useState("Write or call us");
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetch(API_URL);
        if (!response.ok) throw Error("Did not receive expected image");
        const items = await response.json();      

        setCTA_1Line(items.footer.cta1line);
        setCTA_2Line(items.footer.cta2line);
       
        setFetchError(null);
      } catch (err) {
        setFetchError(err.message);
      }
    };

    getData();
  }, []);

  return (
    <>
      <Navigation
        isNavOpen={isNavOpen}
        setIsNavOpen={setIsNavOpen}
        darkMode={darkMode}
      />
      <Outlet />
      <Footer darkMode={darkMode} CTA_1Line={CTA_1Line} CTA_2Line={CTA_2Line}/>
      <ScrollToTop />
    </>
  );
};
