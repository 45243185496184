import React, { useEffect } from "react";
import { Header } from "./Header";
import { PortfolioMain } from "./PortfolioMain";
import { About } from "./About";
import { Clients } from "./Clients";
import {bgDefault} from '../App';

export const Home = ({setBackgroundColor, API_URL }) => {
  useEffect(() => {
    setBackgroundColor(bgDefault);
  }, []);

  return (
    <>
      <Header />
      <PortfolioMain API_URL={API_URL}/>
      <About API_URL={API_URL}/>
      <Clients API_URL={API_URL}/>
    </>
  );
};
