import React from 'react';
import {TopNav} from './../navigation/TopNav';
import {NavPanel} from './../navigation/NavPanel';

export const Navigation = ({isNavOpen, setIsNavOpen, darkMode}) => {
    return (
        <>
            <TopNav setIsNavOpen={setIsNavOpen} darkMode={darkMode}/>
            <NavPanel isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen} darkMode={darkMode}/>
        </>
    )
}
