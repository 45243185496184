import React from 'react'
import LogoBlack from './../logo-short.svg'
import LogoWhite from './../logo-short_white.svg'
import NavBar from './../icons/bars.svg'
import NavBarWhite from './../icons/bars_white.svg'
import '../styles/TopNav.css'

export const TopNav = ({setIsNavOpen, darkMode}) => {

    return (
        <header>
            <a href="https://wytworniastudio.pl"><img className="logo" alt="Wytwornia Studio logo" src={darkMode ? LogoWhite : LogoBlack}/></a>
            <img className="nav-bar" alt="Show menu" src={darkMode ? NavBarWhite : NavBar} onClick={()=> setIsNavOpen(true)}/>
        </header>
    )
}
