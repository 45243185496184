import React from "react";
import "../styles/Header.css";

export const Header = () => {
  return (
    <section className="container">
      <div className="intro">
        <h1>
          Wytwórnia
          <br /> Studio
        </h1>
        <span className="dash"></span>
        <h2>We design for business</h2>
        <p>
          Branding / Web Design / Presentation / E-book / 3D / Product Design /
          Illustration
        </p>
      </div>
    </section>
  );
};
