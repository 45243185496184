import React from "react";
import Cross from "./../icons/cross.svg";
import CrossBlack from "./../icons/cross_black.svg";
import "../styles/NavPanel.css";
import { Link } from "react-router-dom";

export const NavPanel = ({ isNavOpen, setIsNavOpen, darkMode }) => {
  return (
    <div
      className={
        isNavOpen
          ? darkMode
            ? "nav-panel dark-mode"
            : "nav-panel"
          : "nav-panel__hidden"
      }
    >
      <div className="nav-content">
        <img
          src={darkMode ? CrossBlack : Cross}
          alt="Close menu"
          className="nav-close"
          onClick={() => setIsNavOpen(false)}
        />
        <ul className="nav-menu">
          <li>
            <Link
              to="portfolio"
              onClick={() => {
                setIsNavOpen(false);
              }}
            >
              Portfolio
            </Link>
          </li>
          <li>
            <Link
              to="about"
              onClick={() => {
                setIsNavOpen(false);
              }}
            >
              About us
            </Link>
          </li>
        </ul>
        <ul className="nav-contact">
          <li>
            <a href="mailto:office@wytworniastudio.pl">
              office@wytworniastudio.pl
            </a>
          </li>
          <li>
            <a href="tel:+48693195850">+48 693 195 850</a>
          </li>
          <li>
            <a href="">LinkedIn</a>
          </li>
          <li>
            <a href="">Instagram</a>
          </li>
        </ul>
      </div>
    </div>
  );
};
